import { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Container, GridItem } from 'components/Layout';
import {
  Label,
  RequiredLabel,
  SaveButton,
  SaveIcon,
} from 'components/PageLayout';
import { TextArea } from 'components/TextArea';
import { TextField } from 'components/TextField';
import { Select, SelectOption } from 'components/Select';
import { Text } from 'components/Typography';
import { FormikProps, useFormik } from 'formik';
import { GrayBackdrop, Loader } from 'components/Loader';
import { CustomRepairerConnectionForm, Ids } from './types';
import { Checkbox } from 'components/Checkbox';
import {
  AdminAddRepairerConnectionModel,
  AdminRepairerConnections,
  AdminRepairerConnectionSettingModel,
  DeploymentMethod,
} from 'api/resources/models/AutoGenerated';
import {
  coveaCode_access,
  customSetting_access,
  deployToSiteCode_access,
  deploymentType_access,
  externalRepairerName_access,
  fixSiteCode_access,
  fixType_access,
  kindertons_access,
  manufacturer_access,
  overwriteExisting_access,
  schemeCodeOverrideOptions,
  schemeCodeOverride_access,
  selectOptionsDeploymentType,
  selectOptionsManufacturer,
  selectOptionsSysType,
  selectOptionsType,
  sysType_access,
  validation,
  sysType_narg,
  capsLinkExcluded,
  CAPS_V3,
  CAPS_V5,
} from './constants';
import { SelectWithSearch } from 'components/SelectWithSearch';
import { useRepairer } from 'pages/hooks';
import { dateToString, stringToDate } from 'utils/helpers';
import { DatePickerField } from 'components/DatePickerFields';
import {formatDateForAddConnection } from 'utils/helpers';

interface Props {
  isBusy: boolean;
  siteCode: string;
  repairerId: number;
  existingWorkProviders: AdminRepairerConnections[];
  workProviders: AdminRepairerConnectionSettingModel[];
  onSubmit: (data: AdminAddRepairerConnectionModel) => void;
}
export const AddConnectionForm = ({
  isBusy,
  siteCode,
  onSubmit,
  repairerId,
  workProviders,
  existingWorkProviders,
}: Props) => {
  const theme = useTheme();
  const existingWorkProviderIds = existingWorkProviders?.map(
    (e) => e.wpMasterId
  );
  const [currentWorkProviderId, setCurrentWorkProviderId] = useState<
    number | undefined
  >();

  const selectOptionsWorkProvider: SelectOption<number | undefined>[] =
    workProviders.map((data) => ({
      id: data.workProviderID,
      value: data.workProviderID || undefined,
      text: data.workProviderName ?? '',
      optionalParam: { requireExternalCode: data.requireExternalCode },
    }));

  const initialValues = {
    repairerId,
    workProvider:
      selectOptionsWorkProvider.find((e) => e.id == currentWorkProviderId) ??
      null,
    ticketId: '',
    serviceStartDate: '',
    comment: '',
    externalCode: '',
    deploymentType: null,
    schemeCodeOverride: null,
    deployToSiteCode: currentWorkProviderId === Ids.Covea ? siteCode : '',
    deployToEmail: null,
    coveaCode: '',
    sysType: null,
    externalRepairerName: '',
    kindertonsCode: '',
    fixSiteCode: '',
    customSettings: '',
    overwriteExisting: null,
    fixType: null,
    manufacturer: null,
    deploymentMethods: null,
    isExistingConnection: existingWorkProviders.find((e) => e.wpMasterId == currentWorkProviderId) 
                           ? true : false,
  };
  
  const {
    errors,
    touched,
    resetForm,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    values: {
      workProvider,
      ticketId,
      serviceStartDate,
      comment,
      externalCode,
      deploymentType,
      schemeCodeOverride,
      deployToSiteCode,
      coveaCode,
      sysType,
      externalRepairerName,
      kindertonsCode,
      fixSiteCode,
      customSettings,
      overwriteExisting,
      fixType,
      isExistingConnection
    },
  }: FormikProps<CustomRepairerConnectionForm> = useFormik<CustomRepairerConnectionForm>(
    {
      initialValues,
      validateOnMount: true,
      enableReinitialize: true,
      validationSchema: validation,
      onSubmit: (values) => {
        if (workProvider?.id) {
          const data = {
            ...values,
            workProviderID: workProvider.id,
            serviceStartDate: formatDateForAddConnection(values.serviceStartDate) ?? '',
            sysType: workProvider?.id === 90 ? sysType_narg : values.sysType?.value ?? null,
            fixType: values.fixType?.value ?? null,
            schemeCodeOverride: values.schemeCodeOverride ?? null,
            deploymentType:
              values.deploymentType?.id == null
                ? null
                : values.deploymentType?.id,
            overwriteExisting: overwriteExisting_access.includes(
              values.workProvider?.id
            )
              ? values.overwriteExisting
              : null,
            isExistingConnection: values.isExistingConnection
          };
          onSubmit(data);
        }
      },
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetForm(), [workProvider?.id]);

  function convertEnumToArray(enumToBeConverted: any){
    const arrayObjects = [];           
      // Retrieve key and values using Object.entries() method. 
    for (const [propertyKey, propertyValue] of Object.entries(enumToBeConverted)) { 
       // Ignore keys that are not numbers
       if (!Number.isNaN(Number(propertyKey))) {  
         continue;  
       }  
       // Add keys and values to array
       if(propertyKey == CAPS_V3 || propertyKey == CAPS_V5) {
        arrayObjects.push({ id: propertyValue, name: propertyKey }); 
       }
    }        
     return arrayObjects; 
 }
 const enumConvertedToArray = convertEnumToArray(DeploymentMethod);
 const capsLinkOptions : SelectOption<string | undefined>[] = 
  enumConvertedToArray.map((data) => ({
    id: Number(data.id) || 0,
    value: data.name || undefined,
    text: data.name ?? '',
  }))
  const capsLinkNotAvailable = selectOptionsWorkProvider.find(el => el.text === capsLinkExcluded); 
  //const isCapsLinkAvailable = capsLinkNotAvailable?.id === currentWorkProviderId ? false : true;
  const isCapsLinkAvailable = false;


  const { repairer } = useRepairer(repairerId);
  const vizionOption = selectOptionsWorkProvider.find(el => el.text === 'VIZION')
  const isSystemTypeAdvance = repairer?.repairerDetails?.systemType !== 'ADVANCE7';
  const isExternalCodeRequired = workProviders.find(
    (e) => e.workProviderID == workProvider?.id
  )?.requireExternalCode 
  const externalCodeAccess = vizionOption?.id === currentWorkProviderId 
    ? isSystemTypeAdvance 
    : isExternalCodeRequired;
  const schemeCodeOverrideAccess = schemeCodeOverride_access.includes(
    workProvider?.id
  );
  const deployToSiteCodeAccess = deployToSiteCode_access.includes(
    workProvider?.id
  );
  const deploymentTypeAccess = deploymentType_access.includes(workProvider?.id);
  const coveaCodeAccess = coveaCode_access.includes(workProvider?.id);
  const sysTypeAccess = sysType_access.includes(workProvider?.id);
  const externalRepairerNameAccess = externalRepairerName_access.includes(
    workProvider?.id
  );
  const kindertonsCodeAccess = kindertons_access.includes(workProvider?.id);
  const fixSiteCodeAccess = fixSiteCode_access.includes(workProvider?.id);
  const fixTypeAccess = fixType_access.includes(workProvider?.id);
  const overwriteExistingAccess = overwriteExisting_access.includes(
    workProvider?.id
  );
  const customSettingsAccess = customSetting_access.includes(workProvider?.id);
  const manufacturerAccess = manufacturer_access.includes(workProvider?.id);

  const schemeCodeOptions = useMemo(() => {
    if (workProvider && schemeCodeOverrideAccess) {
      const options = schemeCodeOverrideOptions.filter(
        ({ id }) => id === workProvider.id
      )[0]?.options;
      return options || [];
    } else return [];
  }, [schemeCodeOverrideAccess, workProvider]);

  return (
    <form onSubmit={handleSubmit}>
      <GrayBackdrop open={isBusy}>
        <Loader />
      </GrayBackdrop>
      <Container md={12} sm={12} xs={12}>
        <StyledSaveButton type="submit">
          <SaveIcon />
          Save Changes
        </StyledSaveButton>
      </Container>

      <Container>
        {overwriteExistingAccess && (
          <CustomGridItemCheckbox md={6} sm={6} xs={12}>
            <Checkbox
              checked={overwriteExisting ?? false}
              onChange={(e) =>
                setFieldValue('overwriteExisting', Boolean(e.target.checked))
              }
              label="Overwrite Existing"
              color="primary"
            />
          </CustomGridItemCheckbox>
        )}
      </Container>
      <Container direction="row" wrap="wrap" md={6} sm={8} xs={12}>
        <CustomGridItem md={6} sm={6} xs={12}>
          <RequiredLabel>Select Work Provider</RequiredLabel>
          <SelectWithSearch
            selectedId={workProvider?.id}
            options={selectOptionsWorkProvider}
            onChange={(value) => {
              setFieldTouched('workProviderID');
              setFieldValue('workProviderID', value);
              setCurrentWorkProviderId(value);
            }}
            existingOptionsList={existingWorkProviderIds}
            existingOptionColor={theme.palette.repairer.existingConnection}
          />
          {touched.workProvider && errors.workProvider && (
            <Text color={theme.palette.primary.error}>Required</Text>
          )}
        </CustomGridItem>
        <CustomGridItem md={6} sm={6} xs={12}>
          <RequiredLabel>Ticket Number</RequiredLabel>
          <TextField
            fullWidth
            name="ticketId"
            value={ticketId}
            onChange={handleChange}
            onBlur={() => setFieldTouched('ticketId', true)}
            error={!!errors?.ticketId && touched?.ticketId}
            helperText={touched?.ticketId && errors?.ticketId}
          />
        </CustomGridItem>
        <CustomGridItem md={6} sm={6} xs={12}>
          <RequiredLabel>Service Start Date</RequiredLabel>
          <DatePickerField
            name="serviceStartDate"
            value={stringToDate(serviceStartDate)}
            onChange={(date) => {
              setFieldValue('serviceStartDate', date);
            }}
            onBlur={() => {
              setFieldTouched('serviceStartDate', true)
            }}
          />
          {touched.serviceStartDate && errors.serviceStartDate && (
            <Text color={theme.palette.primary.error}>{errors.serviceStartDate}</Text>
          )}
        </CustomGridItem>
        {isCapsLinkAvailable && (
          <CustomGridItem md={6} sm={6} xs={12}>
          <RequiredLabel>CAPS Link</RequiredLabel>
            <Select
              options={capsLinkOptions}
              onChange={(value) => {
                                  setFieldValue('deploymentType', value);
                                  setFieldTouched('deploymentType');
                                }
              }
            />
          {touched.deploymentType && errors.deploymentType && (
            <Text color={theme.palette.primary.error}>Required</Text>
          )}
        </CustomGridItem>
        )}

        {externalCodeAccess && (
          <CustomGridItem md={6} sm={6} xs={12}>
            <RequiredLabel>External Code</RequiredLabel>
            <TextField
              fullWidth
              name="externalCode"
              value={externalCode}
              onChange={handleChange}
              onBlur={() => setFieldTouched('externalCode', true)}
              error={!!errors?.externalCode && touched?.externalCode}
              helperText={touched?.externalCode && errors?.externalCode}
            />
          </CustomGridItem>
        )}
        {manufacturerAccess && (
          <CustomGridItem md={6} sm={6} xs={12}>
            <RequiredLabel>Manufacturer</RequiredLabel>
            <Select
              selectedId={
                selectOptionsManufacturer.find(
                  (el) => el.text === schemeCodeOverride
                )?.id
              }
              options={selectOptionsManufacturer}
              onChange={(value) => {
                setFieldTouched('manufacturer');
                setFieldValue('manufacturer', value.text);
              }}
            />
            {touched.manufacturer && errors.manufacturer && (
              <Text color={theme.palette.primary.error}>Required</Text>
            )}
          </CustomGridItem>
        )}
        {schemeCodeOverrideAccess && (
          <CustomGridItem md={6} sm={6} xs={12}>
            <RequiredLabel>Scheme Code Override</RequiredLabel>
            <Select
              selectedId={
                schemeCodeOptions.find((el) => el.text === schemeCodeOverride)
                  ?.id
              }
              options={schemeCodeOptions}
              onChange={(value) => {
                setFieldTouched('schemeCodeOverride');
                setFieldValue('schemeCodeOverride', value.text);
              }}
            />
            {touched.schemeCodeOverride && errors.schemeCodeOverride && (
              <Text color={theme.palette.primary.error}>Required</Text>
            )}
          </CustomGridItem>
        )}
        {deployToSiteCodeAccess && (
          <CustomGridItem md={6} sm={6} xs={12}>
            <RequiredLabel>Deploy To Sitecode</RequiredLabel>
            <TextField
              fullWidth
              name="deployToSiteCode"
              value={deployToSiteCode}
              onChange={handleChange}
              onBlur={() => setFieldTouched('deployToSiteCode', true)}
              error={!!errors?.deployToSiteCode && touched?.deployToSiteCode}
              helperText={touched?.deployToSiteCode && errors?.deployToSiteCode}
            />
          </CustomGridItem>
        )}
        {deploymentTypeAccess && (
          <CustomGridItem md={6} sm={6} xs={12}>
            <RequiredLabel>Deployment Type</RequiredLabel>
            <Select
              options={selectOptionsDeploymentType}
              selectedId={deploymentType?.id}
              onChange={(value) => {
                setFieldTouched('deploymentType');
                setFieldValue('deploymentType', value);
              }}
            />
            {touched.deploymentType && errors.deploymentType && (
              <Text color={theme.palette.primary.error}>Required</Text>
            )}
          </CustomGridItem>
        )}
        {coveaCodeAccess && (
          <CustomGridItem md={6} sm={6} xs={12}>
            <RequiredLabel>Covea Code</RequiredLabel>
            <TextField
              fullWidth
              name="coveaCode"
              value={coveaCode}
              onChange={handleChange}
              onBlur={() => setFieldTouched('coveaCode', true)}
              error={!!errors?.coveaCode && touched?.coveaCode}
              helperText={touched?.coveaCode && errors?.coveaCode}
            />
          </CustomGridItem>
        )}
        {sysTypeAccess && (
          <CustomGridItem md={6} sm={6} xs={12}>
            <RequiredLabel>SysType</RequiredLabel>
            <Select
              options={selectOptionsSysType}
              selectedId={sysType?.id}
              onChange={(value) => {
                setFieldTouched('sysType');
                setFieldValue('sysType', value);
              }}
            />
            {touched.sysType && errors.sysType && (
              <Text color={theme.palette.primary.error}>Required</Text>
            )}
          </CustomGridItem>
        )}
        {externalRepairerNameAccess && (
          <CustomGridItem md={6} sm={6} xs={12}>
            <Label>External Repairer Name</Label>
            <TextField
              fullWidth
              name="externalRepairerName"
              value={externalRepairerName}
              onChange={handleChange}
              onBlur={() => setFieldTouched('externalRepairerName', true)}
              error={
                !!errors?.externalRepairerName && touched?.externalRepairerName
              }
              helperText={
                touched?.externalRepairerName && errors?.externalRepairerName
              }
            />
          </CustomGridItem>
        )}
        {kindertonsCodeAccess && (
          <CustomGridItem md={6} sm={6} xs={12}>
            <RequiredLabel>Kindertons Code</RequiredLabel>
            <TextField
              fullWidth
              name="kindertonsCode"
              value={kindertonsCode}
              onChange={handleChange}
              onBlur={() => setFieldTouched('kindertonsCode', true)}
              error={!!errors?.kindertonsCode && touched?.kindertonsCode}
              helperText={touched?.kindertonsCode && errors?.kindertonsCode}
            />
          </CustomGridItem>
        )}
        {fixSiteCodeAccess && (
          <CustomGridItem md={6} sm={6} xs={12}>
            <RequiredLabel>Fix Sitecode</RequiredLabel>
            <TextField
              fullWidth
              name="fixSiteCode"
              value={fixSiteCode}
              onChange={handleChange}
              onBlur={() => setFieldTouched('fixSiteCode', true)}
              error={!!errors?.fixSiteCode && touched?.fixSiteCode}
              helperText={touched?.fixSiteCode && errors?.fixSiteCode}
            />
          </CustomGridItem>
        )}
        {fixTypeAccess && (
          <CustomGridItem md={6} sm={6} xs={12}>
            <RequiredLabel>Fix Type</RequiredLabel>
            <Select
              options={selectOptionsType}
              selectedId={fixType?.id}
              onChange={(value) => {
                setFieldTouched('fixType');
                setFieldValue('fixType', value);
              }}
            />
            {touched.fixType && errors.fixType && (
              <Text color={theme.palette.primary.error}>Required</Text>
            )}
          </CustomGridItem>
        )}
        {customSettingsAccess && (
          <CustomGridItem md={6} sm={6} xs={12}>
            <RequiredLabel>Custom Setting</RequiredLabel>
            <TextField
              fullWidth
              name="customSettings"
              value={customSettings}
              onChange={handleChange}
              onBlur={() => setFieldTouched('customSettings', true)}
              error={!!errors?.customSettings && touched?.customSettings}
              helperText={touched?.customSettings && errors?.customSettings}
            />
          </CustomGridItem>
        )}
      </Container>
      <Container direction="column" md={6} sm={8} xs={12}>
        <RequiredLabel>Comments</RequiredLabel>
        <TextArea
          name="comment"
          value={comment ?? ''}
          onChange={handleChange}
          onBlur={() => setFieldTouched('comment', true)}
        />
        {errors.comment && touched.comment && (
          <Text color={theme.palette.primary.error}>{errors.comment}</Text>
        )}
      </Container>
    </form>
  );
};

const StyledSaveButton = styled(SaveButton)`
  margin-top: ${({ theme }) => theme.margin.l};
`;

const CustomGridItem = styled(GridItem)`
      margin-bottom: ${({ theme }) => theme.margin.l};
      padding-right: ${({ theme }) => theme.margin.s};
      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        padding-right: 0;
        margin - bottom: 6px;
      }
      & svg {
        width: 20px;
        height: 20px;
        color: ${({ theme }) => theme.palette.secondary.main};
      }
  `;

const CustomGridItemCheckbox = styled(GridItem)`
  margin-bottom: ${({ theme }) => theme.margin.m};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-bottom: 6px;
  }
`;
